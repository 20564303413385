import { createContext } from 'react';

// function noop() {}

// export const AuthContext = createContext({
//   token: null,
//   userId: null,
//   isAuth: false,
//   role: '',
//   login: noop,
//   logout: noop,
// });

export const AuthContext = createContext();
