import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isPassword2ModalOpen: false,
  isPassword2ModalLoading: false,
};

const password2Slice = createSlice({
  name: 'password2',
  initialState,
  reducers: {
    setIsPassword2ModalOpen: (state, action) => {
      state.isPassword2ModalOpen = action.payload;
    },
    setIsPassword2ModalLoading: (state, action) => {
      state.isPassword2ModalLoading = action.payload;
    },
  },
});

export const {
  setIsPassword2ModalOpen,
  setIsPassword2ModalLoading
} = password2Slice.actions;
export default password2Slice.reducer;
