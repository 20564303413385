import { Button, Drawer } from 'antd';
import { useState, useContext } from 'react';
import { useSelector } from 'react-redux';
import { AuthContext } from '../../context/AuthContext';
import './index.css';
import { useHttp } from '../../hooks/http.hook';

const DrawerProfile = () => {
  const auth = useContext(AuthContext);
  const userInfo = useSelector((state) => state.userInfo.userState);
  const [open, setOpen] = useState(false);
  const { request } = useHttp();

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  const onExit = async () => {
    try {
      await request('/api/auth/logout', 'POST');
      auth.logout();
      window.location.reload();
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <>
      <div className='profileButton'>
        <Button onClick={showDrawer}
          className='profileClick'>
          {userInfo.name ? userInfo.name : userInfo.email}
        </Button>
      </div>
      <Drawer title="Меню" 
        placement="right" 
        onClose={onClose} 
        open={open} 
        footer={<Button type='primary' onClick={onExit} style={{left: '80%'}}>Выйти</Button>}>
      </Drawer>
    </>
  );
}

export default DrawerProfile;