import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { emit } from '../socket';

export const fetchChats = createAsyncThunk(
  'chat/fetchChats',
  async (userId, { rejectWithValue }) => {
    try {
      const response = await fetch(`/api/message/getChats`, {
        headers: {
          authorization: `Bearer ${JSON.parse(localStorage.userData).token}`,
        }
      });
      const data = await response.json();
      if (data.length) {
        for (const chat of data) {
          emit('join-room', `chat-${chat.id}`);
        }
      }
      return data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const chatSlice = createSlice({
  name: 'chat',
  initialState: {
    chats: [],
    status: null,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchChats.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchChats.fulfilled, (state, action) => {
        state.status = 'fulfilled';
        state.chats = action.payload;
      })
      .addCase(fetchChats.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      });
  },
});

export default chatSlice.reducer;
