import { Form, Input, notification } from 'antd';
import { useDispatch } from 'react-redux';
import { setIsPassword2ModalLoading, setIsPassword2ModalOpen } from '../../store/password2Slice';
import { PASSWORD_PATTERN } from '../../utils/consts';

const Password2Form = () => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();

  const handleClose = () => {
    form.resetFields();
    dispatch(setIsPassword2ModalOpen(false));
  };

  const onFinish = async (values) => {
    try {
      dispatch(setIsPassword2ModalLoading(true));

      const res = await fetch('/api/auth/password2', {
        method: 'POST',
        body: JSON.stringify(values),
        headers: {
          'Content-Type': 'application/json',
          authorization: `Bearer ${JSON.parse(localStorage.userData).token}`,
        },
      });

      if (!res.ok) throw new Error();

      notification.success({
        message: 'Пароль обновлен',
        description: 'Ваш пароль был успешно обновлен!',
        placement: 'topRight',
      });

      handleClose();
    } catch (error) {
      console.error('Error on password2:', error);
      notification.error({
        message: 'Ошибка обновления пароля',
        description: 'Не удалось обновить пароль. Пожалуйста, попробуйте снова или обновите страницу.',
        placement: 'topRight',
      });
    } finally {
      dispatch(setIsPassword2ModalLoading(false));
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  return (
    <Form
      form={form}
      name="password2Form"
      style={{
      maxWidth: 600,
      }}
      layout="vertical"
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      autoComplete="off"
    >
      <Form.Item
       label="Пароль"
       name="password"
       rules={[
          {
            required: true,
            message: 'Придумайте новый пароль',
          },
          {
            min: 6,
            pattern: PASSWORD_PATTERN,
            message: 'Не менее 6 символов, минимум одна буква и одна цифра',
          },
       ]}
      >
        <Input.Password />
      </Form.Item>
      <Form.Item
        label="Повторите пароль"
        name="confirmPassword"
        dependencies={['password']}
        rules={[
          {
            required: true,
            message: 'Повторите новый пароль',
          },
          ({ getFieldValue }) => ({
            validator(_, value) {
                if (!value || getFieldValue('password') === value) {
                return Promise.resolve();
                }
                return Promise.reject(new Error('Пароли не совпадают'));
            },
          }),
        ]}
      >
        <Input.Password />
      </Form.Item>
    </Form>
  );
}

export default Password2Form;