import React, { useContext, useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Badge } from 'antd';
import Drawer from '../DrawerProfile/DrawerProfile';
import './index.css';

import { isMobile } from 'react-device-detect';

import {
  LogoutOutlined,
  AppstoreOutlined,
  SnippetsOutlined,
  ClockCircleOutlined,
  PlayCircleOutlined,
  TeamOutlined,
  SolutionOutlined,
  ReadOutlined,
  UsergroupAddOutlined,
  MenuOutlined,
  BookOutlined,
  AreaChartOutlined,
  MessageOutlined,
  BorderOuterOutlined,
  DesktopOutlined,
  PhoneOutlined,
} from '@ant-design/icons';
import { Menu, Dropdown } from 'antd';
import { AuthContext } from '../../context/AuthContext';

const NavBar = () => {
  const auth = useContext(AuthContext);
  const { isAdmin, userState, status } = useSelector((state) => state.userInfo);
  const showUnreadIndicator = useSelector((state) => state.ui.showUnreadIndicator);
  const translationGroup = useSelector((state) => state.userInfo.translationGroup);
  const [isDot, setIsDot] = useState(false);

  useEffect(() => {
    if (typeof translationGroup === 'string') {
      setIsDot(true);
    } else {
      setIsDot(false);
    }
  }, [translationGroup]);

  const logoutHandler = (event) => {
    event.preventDefault();
    auth.logout();
    window.location.reload();
  };

  const items = [
    {
      key: 'forwarder',
      icon: <SnippetsOutlined />,
      label: <NavLink to="/directory/forwarder">Список устройств</NavLink>,
    },
    {
      key: 'messages',
      icon: <MessageOutlined />,
      label: (
        <Badge dot={showUnreadIndicator}>
          <NavLink to="/messages" style={{ color: '#A3ABB2' }}>
            Сообщения
          </NavLink>
        </Badge>
      ),
    },
    {
      key: 'app',
      icon: <AppstoreOutlined />,
      label: <NavLink to="/app">Приложения</NavLink>,
    },
    {
      key: 'geo_data',
      icon: <BorderOuterOutlined />,
      label: <NavLink to="/geo">Геоданные</NavLink>,
    },
    {
      key: 'maps',
      icon: <ReadOutlined />,
      label: <NavLink to="/map">Карты</NavLink>,
    },
    {
      key: 'registration_requests',
      icon: <ClockCircleOutlined />,
      label: <NavLink to="/registered">Запросы</NavLink>,
    },
    {
      key: 'translation',
      icon: <PlayCircleOutlined />,
      label: <NavLink to="/translation">Трансляция</NavLink>,
    },
    {
      key: 'users',
      icon: <TeamOutlined />,
      label: <NavLink to="/users">Пользователи</NavLink>,
    },
    {
      key: 'new_user_requests',
      icon: <SolutionOutlined />,
      label: <NavLink to="/users_requests">Добавление пользователей</NavLink>,
    },
    {
      key: 'group_translations',
      icon: <UsergroupAddOutlined />,
      label: (
        <Badge dot={typeof translationGroup === 'string'}>
          <NavLink to="/group_translations" style={{ color: '#A3ABB2' }}>
            Групповые трансляции
          </NavLink>
        </Badge>
      ),
    },
    {
      key: 'device_offline',
      icon: <BookOutlined />,
      label: <NavLink to="/device_offline">Оффлайн устройства</NavLink>,
    },
    {
      key: 'statistics',
      icon: <AreaChartOutlined />,
      label: <NavLink to="/statistics">Статистика</NavLink>,
    },
    {
      key: 'call_page',
      icon: <PhoneOutlined />,
      label: <NavLink to="/call_page">Звонки</NavLink>,
    },
    {
      key: 'localmachine_registration',
      icon: <DesktopOutlined />,
      label: <NavLink to="/localmachine_registration">Локальные сервера</NavLink>,
    },
    // {
    //   key: "webrtc_translations",
    //   icon: <BookOutlined />,
    //   label: <NavLink to="/webrtc_translations">Групповые звонки</NavLink>
    // }
  ].filter((el) => {
    if (isAdmin) {
      return true;
    } else {
      if (el.key === '1_exit') {
        return true;
      } else {
        if (userState.responseAccesses) {
          return userState.responseAccesses[el.key];
        }
      }
    }
  });

  if (isMobile) {
    items.push({
      key: '1_exit',
      icon: <LogoutOutlined />,
      label: (
        <a href="/" onClick={logoutHandler}>
          {' '}
          Выйти{' '}
        </a>
      ),
    });
  }

  return isMobile ? (
    <Dropdown menu={{ items }} trigger={['click']} placement="bottomLeft">
      <div style={{ position: 'absolute', right: '20px' }}>
        <a onClick={(e) => e.preventDefault()}>
          <MenuOutlined />
        </a>
      </div>
    </Dropdown>
  ) : (
    <>
      <Menu
        mode={'horizontal'}
        theme="dark"
        items={status === 'resolved' ? items : []}
        inlineIndent={5}
        style={isMobile ? { width: '100%', height: '100%' } : {}}
      />
      {status === 'resolved' ? <Drawer /> : null}
    </>
  );
};

export default NavBar;
