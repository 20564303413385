import { useState, useEffect, useRef, useCallback } from 'react';
import { on, removeAllListeners } from '../../socket';
import ACTIONS from '../../utils/socketActions';
import { Badge } from 'antd';
import { useHttp } from '../../hooks/http.hook';
import { NameModal } from './NameModal';
import { EnterModal } from './EnterModal';

export default function MainCallPage() {
  const { request } = useHttp();
  const [rooms, updateRooms] = useState([]);
  const [isEnterModalOpen, setIsEnterModalOpen] = useState(false);
  const rootNode = useRef();

  const fetchRooms = useCallback(async () => {
    const response = await request('api/calls/webrtc_getClientRooms');
    updateRooms(response.rooms);
  }, [updateRooms]);

  useEffect(() => {
    fetchRooms();
    on(ACTIONS.SHARE_ROOMS, async ({ rooms = [] } = {}) => {
      if (rootNode.current) {
        await fetchRooms();
      }
    });

    return () => {
      removeAllListeners(ACTIONS.SHARE_ROOMS);
    };
  }, []);

  return (
    <div ref={rootNode}>
      <NameModal text={'Задайте название для группового звонка'} />
      <h1 style={{ marginLeft: '20px', marginBottom: '20px', fontSize: '24px', fontWeight: 'bold' }}>
        Доступные комнаты
      </h1>
      <div style={{ marginLeft: '20px', display: 'flex', flexWrap: 'wrap', justifyContent: 'flex-start' }}>
        {rooms.map((item) => {
          return (
            <>
              <EnterModal
                id={item.roomID}
                roomName={item.roomLabel}
                isEnterModalOpen={isEnterModalOpen}
                setIsEnterModalOpen={setIsEnterModalOpen}
              />
              <div
                style={{ marginRight: '20px', backgroundColor: '#D0DEEC', borderRadius: '16.5px' }}
                key={item.roomID}
              >
                <Badge count={item.subscribersCount}>
                  <div
                    onClick={() => {
                      setIsEnterModalOpen(true);
                    }}
                    width="100%"
                    style={{
                      cursor: 'pointer',
                      width: '230px',
                      height: '170px',
                      textAlign: 'center',
                    }}
                  >
                    <div
                      style={{
                        marginTop: '10px',
                        marginLeft: '10px',
                        width: '210px',
                        height: '125px',
                        backgroundColor: 'white',
                        borderRadius: '9px',
                      }}
                    />
                    <h2 style={{ marginTop: '12px', fontSize: '16px', fontWeight: 'bold' }}>{item.roomLabel}</h2>
                  </div>
                </Badge>
              </div>
            </>
          );
        })}
      </div>
    </div>
  );
}
