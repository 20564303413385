import React, { useState, useEffect } from 'react';
import { Button, Table, Form, message, Space, Popconfirm } from 'antd';
import { useHttp } from '../../hooks/http.hook';
import { useSelector } from 'react-redux';
import dayjs from 'dayjs';

const LocalmachineRegRequests = () => {
  const { request } = useHttp();
  const [form] = Form.useForm();
  const [data, setData] = useState();
  const [loading, setLoading] = useState(false);
  const userInfo = useSelector((state) => state.userInfo.userState);

  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalData, setTotalData] = useState(10);

  useEffect(() => {
    fetchListData();
  }, []);

  const columns = [
    {
      title: 'Название',
      dataIndex: 'name',
      key: 'name',
      render: (text, record) => {
        return record.name;
      },
    },
    {
      title: 'Ответственный',
      dataIndex: 'user_id',
      key: 'user_id',
      render: (text, record) => {
        return record['user']['id'] ? record['user']['name'] || record['user']['email'] : 'Отсутствует';
      },
    },
    {
      title: 'Департамент',
      dataIndex: 'department',
      key: 'department',
      render: (text, record) => {
        return record.department;
      },
    },
    {
      title: 'Подразделение',
      dataIndex: 'army_post',
      key: 'army_post',
      render: (text, record) => {
        return record.army_post;
      },
    },
    {
      title: 'Дата',
      dataIndex: 'date',
      key: 'date',
      render: (text, record) => {
        return dayjs(record.createdAt).format('DD.MM.YYYY');
      },
    },
    {
      title: 'Действия',
      dataIndex: 'actions',
      key: 'actions',
      fixed: 'right',
      width: '150px',
      render: (text, record) => {
        return (
          <div style={{ textAlign: 'center' }}>
            <Space direction="vertical">
              <Popconfirm
                title="Подтвердить запрос?"
                description="Вы уверены что хотите подтвердить запрос?"
                onConfirm={async () => {
                  approveRequestHandler(record.name);
                }}
                okText="Да"
                cancelText="Отмена"
              >
                <Button type="primary" size="small" style={{ width: '100%', position: 'relative' }}>
                  Подтвердить
                </Button>
              </Popconfirm>
              <Popconfirm
                title="Отклонить запрос?"
                description="Вы уверены что хотите отклонить запрос?"
                onConfirm={() => cancelRequestHandler(record.name)}
                okText="Да"
                cancelText="Отмена"
              >
                <Button type="primary" size="small" style={{ width: '100%', position: 'relative' }} danger>
                  Отменить
                </Button>
              </Popconfirm>
            </Space>
          </div>
        );
      },
    },
  ];

  const fetchListData = async () => {
    try {
      setLoading(true);
      request(
        `/api/localmachine/getPendingRequests?user_id=${userInfo.userId}&limit=${pageSize}&offset=${
          (page - 1) * pageSize
        }`
      )
        .then((resData) => {
          setTotalData(resData.count);
          setData(resData.rows?.map((el, i) => ({ ...el, key: i + 1 })));
        })
        .finally(() => {
          setLoading(false);
        });
    } catch (error) {
      console.log('error-fetchDataForwarder >>>', error);
    }
  };

  const cancelRequestHandler = async (name) => {
    try {
      await request(`api/localmachine/changeRequestStatus`, `PATCH`, {
        name: name,
        status: 'cancelled',
      });
      message.error('Запрос отклонен!');
    } catch (err) {
      console.log(`error cancelRequestHandler: ${err}`);
    } finally {
      fetchListData();
    }
  };

  const approveRequestHandler = async (name) => {
    try {
      await request(`api/localmachine/changeRequestStatus`, `PATCH`, {
        name: name,
        status: 'approved',
      });
      message.success('Запрос успешно подвержден!');
    } catch (err) {
      console.log(`error cancelRequestHandler: ${err}`);
    } finally {
      fetchListData();
    }
  };

  return (
    <>
      <Form form={form}>
        <Table
          loading={loading}
          columns={columns}
          dataSource={data}
          pagination={{
            position: ['bottomCenter'],
            showSizeChanger: false,
            current: page,
            pageSize: pageSize,
            total: totalData,
            onChange: async (page, pageSize) => {
              setPage(page);
              setPageSize(pageSize);
              const response = await request(
                `/api/localmachine/getPendingRequests?user_id=${userInfo.userId}&limit=${pageSize}&offset=${
                  (page - 1) * pageSize
                }`
              );
              setData(response.rows?.map((el, i) => ({ ...el, key: i + 1 })));
            },
          }}
          scroll={{
            x: 1200,
          }}
          bordered
        />
      </Form>
    </>
  );
};

export default LocalmachineRegRequests;
