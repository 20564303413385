import React, {useState, useEffect} from 'react';
import {useHttp} from '../../hooks/http.hook';
import {Card, Row, Col, Empty, Typography, Switch, notification, Select} from 'antd';
import CustomReactFlvPlayer from '../WrapperReactFlvPlayer/ReactFlvPlayer';
import {on, removeAllListeners} from '../../socket';
import {HEIGHT_VALUES, SPAN_CHANGER} from '../../utils/consts';
import imageSVG from '../../images/dns-svgrepo-com.svg';
import './index.css';

const {Option} = Select;

export default () => {
  const [allStreams, setAllStreams] = useState([]);
  const [visibleStreams, setVisibleStreams] = useState([]);
  const [fetchWithAI, setFetchWithAI] = useState(false);
  const [somethingChosen, setSomethingChosen] = useState(false);
  const {request} = useHttp();
  const {Title} = Typography;
  const [api, contextHolder] = notification.useNotification();

  useEffect(() => {
    const handleVisibilityChange = () => {
      if (!document.hidden) {
        getAllStreams(fetchWithAI);
      } else {
        setAllStreams([]);
        setVisibleStreams([]);
      }
    }
    document.addEventListener('visibilitychange', handleVisibilityChange);

    if (fetchWithAI) {
      on('update_translate_vision', () => {
        getAllStreams(fetchWithAI);
      })
    } else {
      on('update_translate', () => {
        getAllStreams(fetchWithAI);
      });
    }

    getAllStreams(fetchWithAI);
    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
      removeAllListeners('update_translate_vision');
      removeAllListeners('update_translate');
    }
  }, [fetchWithAI]);

  const getAllStreams = async (withAI) => {
    const response = await request(withAI ? '/api/streams/vision_list?vision=True' : '/api/streams/list');
    setAllStreams(response);
    setVisibleStreams(prev => prev.length ? prev : response);
  };

  useEffect(() => {
    if (!somethingChosen || (somethingChosen && visibleStreams.length > allStreams.length)) {
      setVisibleStreams(allStreams)
    }
    if (somethingChosen) {
      let newStreams = []
      let responsedStreams = allStreams.map(stream => stream.call_sign)
      for (let i = 0; i < visibleStreams?.length; i++) {
        if (responsedStreams.indexOf(visibleStreams[i].call_sign) != -1) {
          newStreams.push(visibleStreams[i])
        }
        setVisibleStreams(newStreams)
      }
    }
  }, [allStreams])

  const handleSelectChange = (value) => {
    if (value.length > 0) {
      setSomethingChosen(true)
      setVisibleStreams(allStreams.filter(stream => value.includes(stream.device_id)));
    } else {
      setSomethingChosen(false)
      setVisibleStreams(allStreams)
    }
  };

  const getTitle = el => el.call_sign || el.device_id;
  const isManyStreams = length => length > 9;

  const openNotification = () => {
    api.info({
      message: 'AI+ (beta)',
      description:
          'Активен режим просмотра AI+ трансляции: В данном режиме возможна увеличенная задержка при просмотре трансляций!',
    });
  };

  return (
      <>
        {contextHolder}
        <Switch
            checkedChildren="AI+ (beta)"
            unCheckedChildren="Обычный"
            onChange={(checked) => {
              if (checked) {
                openNotification();
              }
              setFetchWithAI(checked);
            }}
            style={{
              position: 'fixed',
              zIndex: 999,
              top: '95%',
              right: '10px',
            }}
        />
        <Select
            mode="multiple"
            allowClear
            placeholder="Выберите трансляции"
            style={{width: '25vw', margin: '10px 0 20px 10px'}}
            onChange={handleSelectChange}
        >
          {allStreams.map(stream => (
              <Option key={stream.device_id} value={stream.device_id}>
                {getTitle(stream)}
              </Option>
          ))}
        </Select>
        <Row>
          {visibleStreams?.length === 0 ? (
              <div style={{position: 'absolute', left: '45%', top: '50%'}}>
                <Empty image={imageSVG} description={<Title level={4}>Нет доступных трансляций</Title>}/>
              </div>
          ) : (
              visibleStreams.map((el, i) => (
                  <Col key={el.device_id}
                       span={isManyStreams(visibleStreams.length) ? 6 : SPAN_CHANGER[visibleStreams.length]} order={2}>
                    <Card size='small' title={getTitle(el)} key={el.device_id}
                          style={{backgroundColor: "#C0C0C0"}}>
                      {el.device_id && (
                          <CustomReactFlvPlayer
                              url={`/translation/live/${el.device_id}.flv/${el.creds}`}
                              height={isManyStreams(visibleStreams.length) ? '27vh' : HEIGHT_VALUES[visibleStreams.length]}
                              hasAudio={false}
                              isLive={true}
                              enableStashBuffer={false}
                              stashInitialSize={0}
                              controls={false}
                              enableWarning={true}
                              key={el.device_id}
                          />
                      )}
                    </Card>
                  </Col>
              ))
          )}
        </Row>
      </>
  );
};
