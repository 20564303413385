import { Drawer, List, Dropdown, Button, Tooltip } from 'antd';
import { UsergroupDeleteOutlined, MoreOutlined } from '@ant-design/icons';
import styles from './GroupCallsPage.module.css';
import { useState } from 'react';

export const UsersListDrawer = ({ users }) => {
  const [isUsersDrawerOpen, setIsUsersDrawerOpen] = useState(false);
  const handleCancel = () => {
    setIsUsersDrawerOpen(false);
  };

  return (
    <>
      <Tooltip placement="bottom" title="Участники">
        <div
          className={`${styles.button} ${styles.users}`}
          style={{
            position: 'fixed',
            top: '80px',
            right: '0',
            transform: 'translateX(-50%)',
            display: 'flex',
            alignItems: 'center',
            gap: '10px',
            zIndex: '1000',
          }}
          onClick={() => setIsUsersDrawerOpen(true)}
        >
          <UsergroupDeleteOutlined />
        </div>
      </Tooltip>
      <Drawer title={'Список участников'} open={isUsersDrawerOpen} onClose={handleCancel}>
        <List
          dataSource={users.filter((peer, index, array) => peer.kind !== 'audio' && array.indexOf(peer) === index)}
          renderItem={(user) => (
            <List.Item className={`${styles.userItem} ${user === users[0] ? styles.currentUser : ''}`}>
              <div className={styles.userInfo}>
                <p>{user === users[0] ? 'Вы' : user.label}</p>
              </div>
            </List.Item>
          )}
        />
      </Drawer>
    </>
  );
};
