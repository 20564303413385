import React, { useContext, useState, useRef } from 'react';
import './index.css';
import { Upload, Button, Input, Space, Typography, message, Progress } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { UploaderContext } from '../../context/UploaderContext';

const Uploader = ({ urlResult, text, index, actualVersions, fetchActualVersions}) => {
  const url = `api/app/${urlResult}`;
  const [contextUploader, setContextUploader] = useContext(UploaderContext); // временно -> заменим на MobX
  const [fileList, setFileList] = useState([]);
  const [uploading, setUploading] = useState(false);
  const [progressState, setProgressState] = useState(0);
  const { Text } = Typography;
  const { TextArea } = Input;
  const textInput = useRef();
  const versionRef = useRef();


  const handleUpload = async () => {
    const versionValue = versionRef.current.resizableTextArea.textArea.value;
    const textValue = textInput.current.resizableTextArea.textArea.value;
    const formData = new FormData();
    const userData = await localStorage.getItem('userData');
    let token = null;
    if (userData) {
      token = await JSON.parse(userData).token;
    }

    fileList.forEach((file) => {
      formData.append('file', file);
      versionValue && formData.append('version', versionValue);
      formData.append('text', textValue);
    })

    setUploading(true);

    const response = new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest();
    
      xhr.open('POST', url);
      xhr.responseType = 'text';
      xhr.setRequestHeader('Authorization', `Bearer ${token}`);
    
      xhr.onload = () => {
        if (xhr.status >= 400) {
          reject(xhr.response);
        } else {
          resolve(xhr.response);
          setFileList([]);
          setUploading(false);
          fetchActualVersions().catch(error => console.error('Error fetching actual versions:', error));
        }
      }
    
      xhr.upload.onprogress = (event) => {
        setProgressState(+Math.floor((event.loaded / event.total) * 100));
      }
    
      xhr.upload.onerror = () => {
        reject(xhr.statusText);
      }
    
      xhr.send(formData);
    });
    
    response
      .then(() => {
        setContextUploader(!contextUploader);
      })
      .catch((error) => {
        console.error('Error:', error);
        setUploading(false);
      });
    
    }

  const propsUpload = {
    name: 'file',
    listType: 'picture',
    onRemove: (file) => {
      setFileList([]);
    },
    beforeUpload: (file) => {
      if (file.name.split('.').pop() === 'apk') {
        message.success(`Файл ${file.name} готов для загрузки!`);
        setFileList([file]);
      } else {
        message.error('Возможно загрузить только .apk файлы');
      }
      return false;
    },
    fileList,
  };

  const GetActualVerEye = (params) => {
    return <Text style={{ width: 'auto' }} type='secondary'>{`${actualVersions[params.obj]?.value ? actualVersions[params.obj].value : 'Версия не найдена'}`}</Text>
  }

  const TextField = () => {
    return (
      <>
        <TextArea
          // maxLength={1000}
          style={{
            // height: 150,
            position: 'relative',
            marginTop: 25,
            marginBottom: 20,
            right: 10,
            resize: 'none',
          }}
          required={true}
          allowClear={true}
          ref={versionRef}
          placeholder="Версия"
        />
        <TextArea
          showCount
          maxLength={1000}
          style={{
            height: 150,
            position: 'relative',
            marginTop: 25,
            marginBottom: 20,
            right: 10,
            resize: 'none',
          }}
          required={true}
          allowClear={true}
          ref={textInput}
          placeholder="Описание"
        />
        {!fileList.length ? <Button type='primary' shape="round" onClick={handleUpload} style={{ float: 'right', right: 10, marginBlock: 10 }} disabled>Сохранить</Button>
          :
          <Button type='primary' shape="round" onClick={handleUpload} style={{ float: 'right', right: 10, marginBlock: 10 }}>Сохранить</Button>}
      </>
    );
  }

  return (
    <div className="Uploader__container">
      <Upload {...propsUpload}>
        <Space size={'large'}>
          <GetActualVerEye obj={index} />
          <Button type='primary' style={{ marginRight: 10, top: 5 }} icon={<UploadOutlined />} loading={uploading}>
            Обновить {text}
          </Button>
        </Space>
      </Upload>
      {uploading ? <Progress status='active' percent={progressState} /> : null}
      <TextField />
    </div>
  );
};

export default Uploader;
