import jwt_decode from 'jwt-decode';

export const fetchRefreshTokens = async () => {
  let response = null;
  
  try {
    response = await fetch('/api/auth/refresh-tokens', {
      method:'POST',
      headers: {
        authorization: `Bearer ${JSON.parse(localStorage.userData).token}`,
      },
    });
    const data = response.ok ? await response.json() : null;
    const error = response.ok ? null : new Error(`Failed to fetch: ${response.status}`);

    localStorage.setItem(
      'userData',
      JSON.stringify({
        userId: jwt_decode(data.tokens.access.token).sub,
        token: data.tokens.access.token,
      })
    );

    return { response, data, error };
  } catch (err) {
    return { response, data: null, error: err };
  }
}