import React from "react";
import LocalmachineRequests from "../../components/LocalmachineRequests/LocalmachineRequests";

 
function LocalmachineRequestsPage() {
	return (
		<>
			<div className="usersPageWrapper">
        		<LocalmachineRequests />
			</div>
		</>
	)
}

export default LocalmachineRequestsPage;