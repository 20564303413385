import React, { useState, useEffect } from 'react';
import { ReactFlvPlayer } from 'react-flv-player';
import InfiniteScroll from 'react-infinite-scroll-component';
import {
  Button,
  Space,
  notification,
  Modal,
  Select,
  Form,
  Checkbox,
  Col,
  Card,
  Empty,
  Row,
  FloatButton,
  List,
  Divider,
} from 'antd';
import { UserAddOutlined, DatabaseOutlined, CloseOutlined, UsergroupAddOutlined } from '@ant-design/icons';
import { useHttp } from '../../hooks/http.hook';
import { useSelector, useDispatch } from 'react-redux';
import { on, emit } from '../../socket';
import { HEIGHT_VALUES, SPAN_CHANGER } from '../../utils/consts';
import { fetchTranslationGroup } from '../../store/userSlice';

const GroupTranslationsContent = () => {
  const dispatch = useDispatch();
  const { request } = useHttp();
  const [api, contextHolder] = notification.useNotification();
  const [addUserForm] = Form.useForm();
  const { userId, name, email } = useSelector((state) => state.userInfo.userState);
  const translationGroup = useSelector(state => state.userInfo.translationGroup);
  const devicesToShow = useSelector(state => state.userInfo.streamingDevices);
  const [visualButtons, setVisualButtons] = useState(false);
  const [isAddUserModalOpen, setAddUserModalOpen] = useState(false);
  const [usersWithCorrectAccess, setusersWithCorrectAccess] = useState([]);
  const [isRoomCreator, setIsRoomCreator] = useState(false);


  const [allStreams, setAllStreams] = useState([]); //* Все доступные стримы
  const [showStreams, setShowStreams] = useState([]); //* Выбранные стримы
  const [streamsIsLive, setStreamsIsLive] = useState([]);

  // Active spectators
  const [spectators, setSpectators] = useState([]);

  const apiSuccess = (message, description) => {
    return {
      message: message,
      description: description,
      placement: 'topLeft'
    }
  };

  useEffect(() => {
    setAllStreams([...devicesToShow])
  }, [devicesToShow]);

  useEffect(() => { // Если пользователь не находится на странице 'Групповые трансляции'
    on('join_request', (roomId) => {
      emit('join-room', roomId);
      dispatch(fetchTranslationGroup());
      setVisualButtons(true);
    });

    on('reject_request', (data) => {
      api.success(apiSuccess(`Невозможно добавить пользователя`, `Пользователь ${data} уже состоит в какой-то группе!`));
    })

    on('changed-checkboxed', (data) => {
      setAllStreams(data);
    });

    on('startData', (data) => {
      setAllStreams(data);
    });

    on('updateList', () => {
      fetchUsersList();
    });

    on('leave-now', () => {
      api.error(apiSuccess(`Групповая трансляция`, `Вы были исключены создателем группы!`));
      leaveRoom();
    });

    on('adminLeaved', () => {
      window.location.reload();
    })

    on('showInfoMessage', (text) => {
      api.info(apiSuccess('Уведомление', text));
    })

    if (translationGroup === 'string') {
      fetchUsersList();
      setVisualButtons(true);
      emit('checkTranslations-onOpen');
    }
  }, []);

  useEffect(() => {
    if (typeof translationGroup === 'string' && +translationGroup.replace('room-', '') === userId) {
      fetchUsersList();
      emit('checkTranslations-onOpen');
      setIsRoomCreator(true);
      setVisualButtons(true);
      fetchUserWithAccess();
      effectFunc();
    } else if (typeof translationGroup === 'string') {
      fetchUsersList();
      setAllStreams([...devicesToShow]);
      setVisualButtons(true);
      emit('checkTranslations-onOpen');
    }
  }, [translationGroup]);

  useEffect(() => {
    setStreamsIsLive(showMedia(showStreams.length))
  }, [allStreams, showStreams]);

  const notificationSuccess = (message, description, status) => {
    switch (status) {
      case 200:
        api.success(apiSuccess(message, description));
        setVisualButtons(true);
        break
      case 400:
        api.error(apiSuccess(message, description));
        break;
    }
  }

  const SpectatorsList = () => {
    return (
      <div
        id="scrollableDiv"
        style={{
          overflow: 'auto',
          padding: '0 16px',
          border: '1px solid rgba(140, 140, 140, 0.35)',
          position: 'relative',
          left: '1%',
          bottom: 0,
        }}
      >
        <InfiniteScroll
          dataLength={spectators.length}
          endMessage={<Divider plain>Список пользователей</Divider>}
          scrollableTarget="scrollableDiv"
        >
          <List
            dataSource={spectators}
            renderItem={(item) => (
              <List.Item key={item.name || 'нет данных'}>
                <List.Item.Meta
                  title={<a>{item.email || 'нет данных'}</a>}
                  description={`room-${item.id}` === translationGroup ? 'Администратор' : item.tel || 'нет данных'}
                />
                {isRoomCreator && !(`room-${item.id}` === translationGroup) && <div><Button icon={<CloseOutlined />} size='small' onClick={() => removeUser(item)} danger></Button></div>}
              </List.Item>
            )}
          />
        </InfiniteScroll>
      </div>
    );
  }

  const removeUser = (user) => {
    emit('remove_from_room', user);
  }

  const fetchUsersList = async () => {
    const usersList = await request(`api/group_translation/get_actual_users`);
    setSpectators(usersList.data);
  }

  const OptionButtons = () => {
    return (
      <>
        <div style={{ position: 'absolute', right: '5%', top: '8%', zIndex: 100 }}>
          {isRoomCreator ?
            <>
              <UserListModal />
              <AddUserModal />
            </>
            :
            <UserListModal />
          }
        </div>
      </>
    )
  }

  const createGroup = async () => {
    const createRoom = await request('/api/group_translation/create_group', 'POST', { userId: userId });
    dispatch(fetchTranslationGroup());
    emit('join-room', `room-${userId}`);
    setVisualButtons(true);
    return notificationSuccess(createRoom.message, null, createRoom.status)
  }

  const leaveRoom = async () => {
    emit('leave-room', {user: name || email})
    dispatch(fetchTranslationGroup());
    setVisualButtons(false);
  }

  // ? ----------- Добавить пользователя ---------------
  const fetchUserWithAccess = async () => {
    const receivedUsers = await request('/api/group_translation/get_users_with_access');
    setusersWithCorrectAccess(receivedUsers.data.map((el) => {
      return { label: el.name || el.email, value: el.id }
    }));
  }

  const showAddUserModal = async () => {
    setAddUserModalOpen(true);
  }

  const handleAddUserModalOk = () => {
    addUserForm.validateFields()
      .then(async (result) => {
        if (result !== undefined) {

          addUserForm.resetFields();
          if (result.names) {
            emit('join_group', result.names);
          }

        }
      });
    setAddUserModalOpen(false);
  }

  const handleAddUserModalCancle = () => {
    setAddUserModalOpen(false);
  }

  const UserListModal = () => {
    const [userListOpen, setUserListOpen] = useState(false);
    return (
      <>
        <Button type='primary' onClick={() => setUserListOpen(true)} icon={<UsergroupAddOutlined />} style={{ margin: 5 }} />
        <Modal title='Акутальный список пользователей'
          open={userListOpen}
          footer={[]}
          onCancel={() => setUserListOpen(false)}
        >
          <>
            <SpectatorsList />
          </>
        </Modal>
      </>
    )
  }

  const AddUserModal = () => {

    return (
      <>
        <Button type='primary' onClick={showAddUserModal} icon={<UserAddOutlined />}>Добавить пользователя</Button>
        <Modal title='Выберите пользователей'
          open={isAddUserModalOpen}
          onOk={handleAddUserModalOk}
          onCancel={handleAddUserModalCancle}>
          <Form form={addUserForm}>
            <Form.Item
              label="Позывной"
              name="names">
              <Select
                mode='multiple'
                placeholder='Выберите пользователей для групповой трансляции'
                style={{ width: '100%' }}
                options={usersWithCorrectAccess}
              />
            </Form.Item>
          </Form>

        </Modal>
      </>
    )
  }
  // ? ---------------------------------------------------

  // ? -------------------- Логика трансляций ------------

  const getFullListStreams = () => [...allStreams.filter(el => !showStreams.some(stream => stream.device_id === el.device_id)), ...showStreams].filter((el) => el.length !== 0)

  const effectFunc = async () => {
    await getAllStreams()
    on('update_translate', async () => {
      await getAllStreams()
    })
  }

  const getAllStreams = async () => {
    const response = await request('/api/streams/list');
    setAllStreams(response);
  }

  const showTranslation = async (el) => {
    try {
      if (showStreams.some(stream => stream.device_id === el.device_id)) { //* Убрать трансляцию с показа
        isRoomCreator && emit('close-translation', el.device_id);
        setShowStreams(showStreams.filter(stream => stream.device_id !== el.device_id));
      } else { //* Вывести трансляцию
        isRoomCreator && emit('show-translation', [...showStreams, el])
        setShowStreams((showStreams) => [...showStreams, el]);
      }
    } catch (err) {
      setShowStreams(showStreams.filter(stream => stream.device_id !== el.device_id));
      console.log(`showTranslation error: ${err}`);
    }

  }

  const checkBoxGenerator = (el) => { // CheckBox Logic
    if (isRoomCreator) {
      emit('add_remove_translations', { showStreams: showStreams, translationGroup: translationGroup });
    }
    return <Checkbox checked={showStreams.some(streamIsShow => streamIsShow.device_id === el.device_id)}
      disabled={showStreams.length > 8 ? !showStreams.some(streamIsShow => streamIsShow.device_id === el.device_id) : false}
      style={{ backgroundColor: '#F5F5F5', width: '100%', height: '100%' }}
      onClick={() => showTranslation(el)}>
      <b style={{ fontSize: '1.3em' }}>{getTitle(el)}</b>
    </Checkbox>;
  }

  const showMedia = (length) => showStreams.map((el, i) => <Col key={i} span={SPAN_CHANGER[length]} order={2}>
    <Card size='small' title={getTitle(el)}>
      {allStreams.some(stream => stream.device_id === el.device_id) ? <ReactFlvPlayer
        url={`/translation/live/${el.device_id}.flv`}
        height={HEIGHT_VALUES[length]}
      /> : <div style={{ height: HEIGHT_VALUES[length] }}>Трансляция недоступна</div>}
    </Card>
  </Col>);

  const getTitle = el => {
    if (Object.keys(el).length !== 0) {
      return el.call_sign || el.device_id;
    }
    return null;
  }

  const ExitButton = () => {
    return (
      translationGroup?.room_id || visualButtons ? <Button
        type='primary'
        style={{ position: 'absolute', right: '5%', bottom: '5%' }}
        onClick={leaveRoom}
        danger>Выйти</Button> : null
    )
  }


  // ? ------------------------------------------------------

  return (
    <>
      {contextHolder}
      {!visualButtons ?
        <Button
          onClick={createGroup}
          style={{ position: 'absolute', left: '50%', top: '50%' }}
          type='primary'
        >Создать</Button>
        :
        <>
          <OptionButtons />
          <Row>
            {streamsIsLive.map(el => el)}
          </Row>
          {getFullListStreams().length > 0 ? <FloatButton.Group
            trigger="click"
            type="primary"
            style={{
              right: '7%',
              bottom: '10%',
            }}
            icon={<DatabaseOutlined />}
          >
            <div style={{ width: '100px', position: 'relative' }}>
              {getFullListStreams().map((el, i) => <Space key={i}>{checkBoxGenerator(el)}</Space>)}
            </div>
          </FloatButton.Group> : streamsIsLive.length > 0 ? '' : <div style={{ position: 'absolute', left: '45%', top: '50%' }}><Empty description='Нет доступных трансляций' /></div>}
        </>}
      < ExitButton />
    </>
  )
}

export default GroupTranslationsContent;