import { Button, Modal } from 'antd';
import Password2Form from '../Password2Form/Password2Form';
import { useDispatch, useSelector } from 'react-redux';
import { setIsPassword2ModalOpen } from '../../store/password2Slice';

const Password2Modal = () => {
  const isPassword2ModalOpen = useSelector(state => state.password2.isPassword2ModalOpen);
  const isPassword2ModalLoading = useSelector(state => state.password2.isPassword2ModalLoading);
  const dispatch = useDispatch();

  const closePassword2Modal = () => {
    dispatch(setIsPassword2ModalOpen(false));
  };

  return (
    <Modal
      title="Обновление пароля"
      open={isPassword2ModalOpen}
      onCancel={closePassword2Modal}
      footer={[
        <Button key="cancel" onClick={closePassword2Modal}>
          Отменить
        </Button>,
        <Button form="password2Form" key="submit" type="primary" htmlType="submit" loading={isPassword2ModalLoading}>
          Обновить пароль
        </Button>
      ]}
    >
      <Password2Form />
    </Modal>
  );
}

export default Password2Modal;